import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "developerman.jpg" }) {
        base
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Nosotros" />
      <div className="container page-wrapper">
        <div className="row">
          <div className="col-lg-6">
            <div className="service-img-wrapper">
              <Img
                fluid={data.file.childImageSharp.fluid}
                alt={data.file.base}
              />
            </div>
          </div>

          <div className="col-lg-6 col-xl-5 ml-xl-auto">
            <div className="special_description_content">
              <div className="section_heading">
                <h2 className="title-text">Nuestro Enfoque</h2>
                <div className={`mb-5 underline_shape`}></div>
              </div>

              <p className="paragpah-text">
                En vangTry aplicamos metodologias agiles en combinación con
                metodos tradicionales para el desarrollo de proyectos
              </p>
              <p className="paragpah-text">
                Nuestra metodología de trabajo se enfoca en priorizar sus
                necesidades de negocio, y generar valor en etapas tempranas de
                los proyectos, para así beneficiar al negocio en forma tangible.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutUs
